import * as React from 'react';
import { useState } from 'react';
import { HttpHelpers } from '../HttpHelpers';
import { useRef } from 'react';
import ReactPlayer from 'react-player/lazy';
import { useEffect } from 'react';
import { constants } from '../constants';
import { useHistory } from 'react-router-dom';
import { List, ListItem, ListItemText } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Job } from '../JobView';

interface IProps {
    job: Job;
}

const RoadshowVideoPlayer = (props: IProps) => {
    const history = useHistory();
    const [timestampList, setTimestampList] = useState([]);

    // useEffect(() => {
    //
    //     const refreshTokens = () => {props.refreshTokensCallback(history)};
    //
    //
    //     HttpHelpers.getJson(
    //         constants.urlPrefix + "/job/roadshow/meta/" + props.jobId,
    //         true,
    //         props.accessToken,
    //         refreshTokens
    //     ).then(result => {
    //         if (result.status === 200) {
    //             console.log("doing it", result.body)
    //             setTimestampList(result.body.timestamps)
    //         }
    //     }).catch(function (error) {
    //         console.log('Request failed', error);
    //     });
    // }, [props.jobId]);

    const playerRef = useRef(null);

    //disable watch ahead:
    //https://stackoverflow.com/questions/11903545/how-to-disable-seeking-with-html5-video-tag

    //Cant fully disable download
    //https://stackoverflow.com/questions/9756837/prevent-html5-video-from-being-downloaded-right-click-saved/9756909#9756909

    const buildListItem = item => {
        let str = item.title + ' ' + item.timestampString;
        return (
            <ListItem
                button={true}
                onClick={() => {
                    playerRef.current.seekTo(item.timestampSeconds);
                }}>
                <ListItemText primary={str} />
            </ListItem>
        );
    };

    const maybeTimestampList = () => {
        if (props.job) {
            return (
                <List>
                    {props.job.roadshowMeta.timestamps.map((item, i) => {
                        return buildListItem(item);
                    })}
                </List>
            );
        }
    };

    return (
        <>
            <Grid container>
                <Grid item xs={3}>
                    {maybeTimestampList()}
                </Grid>
                <Grid item xs={7}>
                    <ReactPlayer
                        ref={playerRef}
                        url={HttpHelpers.buildUrl(
                            '/api/video/' +
                                props.job.key +
                                '/' +
                                props.job.roadshowMeta.videoFileName
                        )}
                        config={{
                            file: { attributes: { controlsList: 'nodownload' } }
                        }}
                        onContextMenu={e => e.preventDefault()}
                        className='react-player'
                        controls
                        width='100%'
                        height='100%'
                    />
                </Grid>
            </Grid>
        </>
    );
};

export { RoadshowVideoPlayer };
