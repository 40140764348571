//external imports
import React from 'react';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import * as H from 'history';
import {
    Grid,
    List,
    ListItem,
    ListItemText,
    Box,
    Paper,
    makeStyles,
    createStyles,
    Theme
} from '@material-ui/core';
import clsx from 'clsx';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
//own imports
import { HttpHelpers } from '../HttpHelpers';
import { constants } from '../constants';
import { Job } from '../JobView';
import AudioPlayer from './AudioPlayer';
import RoadshowProgression from './RoadshowProgression';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2),
            textAlign: 'left',
            color: theme.palette.text.secondary
        },
        topMargin: {
            marginTop: theme.spacing(3)
        }
    })
);

interface IRoadshowSlidePlayerProps {
    job: Job;
    accessToken: string;
    refreshTokensCallback: (history: H.History<H.LocationState>) => void;
}

const RoadshowSlidePlayer: React.FunctionComponent<
    IRoadshowSlidePlayerProps
> = (props: IRoadshowSlidePlayerProps): React.ReactElement => {
    //constants
    const classes = useStyles();
    //state
    const history: H.History<H.LocationState> = useHistory();
    const [currentSlideImage, setCurrentSlideImage] = useState('');
    const [nextSlideImage, setNextSlideImage] = useState('');
    const [currentSlideAudio, setCurrentSlideAudio] = useState('');
    const [nextSlideAudio, setNextSlideAudio] = useState('');
    const [currentSlideNumber, setCurrentSlideNumber] = useState(1);
    const [autoPlay, setAutoPlay] = useState(false);
    const [userRoadshowProgression, setUserRoadshowProgression] =
        useState<RoadshowProgression>(null);
    const [maxAudioProgress, setMaxAudioProgress] = useState(0);

    const roadshowHasAudio = !(
        props.job.roadshowMeta.timestamps &&
        props.job.roadshowMeta.timestamps.every(t => t.timestampSeconds === 0)
    );

    const refreshTokens = () => props.refreshTokensCallback(history);

    const __requestRoadshowSlide = async (
        slideNumber: number,
        isNext = false
    ): Promise<void> => {
        try {
            const { body } = await HttpHelpers.getBlob(
                `${constants.urlPrefix}/job/roadshow/slide/${props.job.key}/${slideNumber}`,
                true,
                props.accessToken,
                refreshTokens
            );
            const slideImageObjectUrl = window.URL.createObjectURL(body);
            if (isNext) {
                setNextSlideImage(slideImageObjectUrl);
            } else {
                setCurrentSlideImage(slideImageObjectUrl);
            }
        } catch (e) {
            console.error(
                `Slide request failed for slideNumber: ${slideNumber}`,
                e
            );
        }
    };

    const __requestRoadshowSlideAudio = async (
        slideNumber: number,
        isNext = false
    ): Promise<void> => {
        try {
            const { body } = await HttpHelpers.getBlob(
                `${constants.urlPrefix}/job/roadshow/audio/${props.job.key}/${slideNumber}`,
                true,
                props.accessToken,
                refreshTokens
            );
            const slideAudioObjectUrl = window.URL.createObjectURL(body);
            if (isNext) {
                setNextSlideAudio(slideAudioObjectUrl);
            } else {
                setCurrentSlideAudio(slideAudioObjectUrl);
            }
        } catch (e) {
            console.error(
                `Audio request failed for slideNumber: ${slideNumber}`,
                e
            );
        }
    };

    const __requestRoadshowProgress =
        async (): Promise<RoadshowProgression> => {
            try {
                const { body } = await HttpHelpers.getJson(
                    `${constants.urlPrefix}/job/roadshow/progression/${props.job.key}`,
                    true,
                    props.accessToken,
                    refreshTokens
                );
                return body;
            } catch (e) {
                console.error('User progress request failed', e);
            }
        };

    const __updateRoadshowProgress = async (
        roadshowProgression: RoadshowProgression
    ): Promise<void> => {
        try {
            await HttpHelpers.putJson(
                `${constants.urlPrefix}/job/roadshow/progression`,
                roadshowProgression,
                props.accessToken,
                refreshTokens
            );
        } catch (e) {
            console.error('User progress update request failed', e);
        }
    };

    const __pageToSlide = (slideNumber: number): void => {
        let nextSlideCached = false;
        if (
            slideNumber === currentSlideNumber + 1 &&
            nextSlideImage &&
            nextSlideAudio
        ) {
            setCurrentSlideImage(nextSlideImage);
            if (roadshowHasAudio) {
                setCurrentSlideAudio(nextSlideAudio);
            }
            nextSlideCached = true;
        }
        setCurrentSlideNumber(slideNumber);
        if (slideNumber > userRoadshowProgression?.lastSlideNumber) {
            setUserRoadshowProgression(prevUserRoadshowProgression => ({
                ...prevUserRoadshowProgression,
                lastSlideNumber: slideNumber,
                lastSlideAudioProgress: 0
            }));
        }
        if (!nextSlideCached) {
            //request current image/audio
            __requestRoadshowSlide(slideNumber);
            if (roadshowHasAudio) {
                __requestRoadshowSlideAudio(slideNumber);
            }
        }
        //request next image/audio
        if (slideNumber <= props.job.roadshowMeta.timestamps.length - 1) {
            __requestRoadshowSlide(slideNumber + 1, true);
            if (roadshowHasAudio) {
                __requestRoadshowSlideAudio(slideNumber + 1, true);
            }
        }
    };

    //on userRoadshowProgression changed
    useEffect(() => {
        if (userRoadshowProgression) {
            setMaxAudioProgress(userRoadshowProgression.lastSlideAudioProgress);
            __updateRoadshowProgress(userRoadshowProgression);
        }
    }, [userRoadshowProgression]);

    //on componentDidMount
    useEffect(() => {
        __requestRoadshowProgress().then(response => {
            setUserRoadshowProgression(response);
            __pageToSlide(response.lastSlideNumber);
        });
    }, []);

    const handleMaxAudioProgressUpdated = (maxProgress: number): void => {
        //if we have been listening to this audio for a minute update the server
        if (
            maxProgress % 60 === 0 &&
            currentSlideNumber >= userRoadshowProgression.lastSlideNumber
        ) {
            setUserRoadshowProgression(prevUserRoadshowProgression => ({
                ...prevUserRoadshowProgression,
                lastSlideAudioProgress: maxProgress
            }));
        }
        setMaxAudioProgress(maxProgress);
    };

    const handleAudioEnded = (): void => {
        if (currentSlideNumber === props.job.roadshowMeta.timestamps.length) {
            setAutoPlay(false);
            setUserRoadshowProgression(prevUserRoadshowProgression => ({
                ...prevUserRoadshowProgression,
                lastSlideAudioProgress: Number.MAX_VALUE
            }));
        } else {
            __pageToSlide(currentSlideNumber + 1);
        }
    };

    return (
        <Paper elevation={3} className={clsx(classes.paper, classes.topMargin)}>
            <Grid container>
                <Grid
                    container
                    direction='column'
                    justifyContent='space-between'
                    item
                    xs={12}
                    md={3}
                    lg={3}
                    style={{ maxHeight: '95vh' }}>
                    {props.job ? (
                        <>
                            <List
                                style={{
                                    maxHeight: roadshowHasAudio
                                        ? '80vh'
                                        : '95vh',
                                    overflowY: 'auto'
                                }}>
                                {props.job.roadshowMeta.timestamps.map(item => (
                                    <ListItem
                                        button={true}
                                        key={item.slideNumber}
                                        style={
                                            item.slideNumber ===
                                            currentSlideNumber
                                                ? { color: '#255026' }
                                                : null
                                        }
                                        autoFocus={
                                            item.slideNumber ===
                                            currentSlideNumber
                                        }
                                        onClick={() => {
                                            __pageToSlide(item.slideNumber);
                                        }}
                                        disabled={
                                            roadshowHasAudio
                                                ? item.slideNumber >
                                                  userRoadshowProgression?.lastSlideNumber
                                                : item.slideNumber >
                                                  userRoadshowProgression?.lastSlideNumber +
                                                      1
                                        }>
                                        <ListItemText
                                            primary={item.title}
                                            secondary={
                                                item.timestampString.split(
                                                    '.'
                                                )[0]
                                            }
                                            primaryTypographyProps={{
                                                style: {
                                                    wordWrap: 'break-word'
                                                }
                                            }}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                            {roadshowHasAudio && userRoadshowProgression ? (
                                <Box padding={1} style={{ maxHeight: '15vh' }}>
                                    <AudioPlayer
                                        audioStartSeconds={0}
                                        source={currentSlideAudio}
                                        maxProgress={
                                            currentSlideNumber <
                                            userRoadshowProgression?.lastSlideNumber
                                                ? Number.MAX_VALUE
                                                : maxAudioProgress
                                        }
                                        jumpInterval={5}
                                        autoPlay={autoPlay}
                                        onPlayClicked={() => setAutoPlay(true)}
                                        onMaxProgressUpdated={
                                            handleMaxAudioProgressUpdated
                                        }
                                        onAudioEnded={handleAudioEnded}
                                    />
                                </Box>
                            ) : null}
                        </>
                    ) : null}
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={10}
                    lg={9}
                    style={{ maxHeight: '95vh', textAlign: 'center' }}>
                    {currentSlideImage ? (
                        <img
                            src={currentSlideImage}
                            style={{
                                maxHeight: '100%',
                                maxWidth: '100%'
                            }}
                        />
                    ) : (
                        <Skeleton
                            style={{
                                height: '100%',
                                minHeight: '600px'
                            }}
                        />
                    )}
                </Grid>
            </Grid>
        </Paper>
    );
};

export default RoadshowSlidePlayer;
